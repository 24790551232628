import React, { useState, Fragment, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Polygon,
  // Tooltip,
  Popup,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import "leaflet-defaulticon-compatibility";
import { CDN_URL, getSlugType } from "../utils/api";

const getColorForValue = (value, property) => {
  const colorScales = {
    kd: {
      SWASEMBADA: "#03A00A",
      SWAKARYA: "#C00000",
      SWADAYA: "#327A6D",
    },
    idm: ["#03A00A", "#D3D714", "#C00000", "#0D77C3", "#327A6D"],
    sdgs: ["#03A00A", "#D3D714", "#C00000", "#0D77C3", "#0DC3A2", "#327A6D"],
    ar: ["#03A00A", "#D3D714", "#C00000", "#0D77C3", "#0DC3A2", "#327A6D"],
    program: ["#03A00A", "#D3D714", "#C00000", "#0D77C3", "#0DC3A2", "#327A6D"],
    sda: ["#03A00A", "#D3D714", "#C00000", "#0D77C3", "#0DC3A2", "#327A6D"],
    sdm: ["#03A00A", "#D3D714", "#C00000", "#0D77C3", "#0DC3A2", "#327A6D"],
    lk: ["#03A00A", "#D3D714", "#C00000", "#0D77C3", "#0DC3A2", "#327A6D"],
    sarpras: ["#03A00A", "#D3D714", "#C00000", "#0D77C3", "#0DC3A2", "#327A6D"],
  };

  if (colorScales[property]) {
    if (property === "kd") {
      return colorScales[property][value] || "#000000";
    } else {
      const thresholds = {
        idm: [0.491, 0.599, 0.707, 0.815, 999999],
        sdgs: [10, 20, 30, 50, 80, 999999],
        ar: [0, 3, 5, 8, 10, 999999],
        program: [0, 3, 5, 8, 10, 999999],
        sda: [0, 3, 5, 8, 10, 999999],
        sdm: [100, 500, 1000, 3000, 5000, 999999],
        lk: [0, 3, 5, 8, 10, 999999],
        sarpras: [0, 3, 5, 8, 10, 999999],
      };

      const thresholdArray = thresholds[property];
      const colorScale = colorScales[property];

      for (let i = 0; i < thresholdArray.length; i++) {
        if (value <= thresholdArray[i]) {
          return colorScale[i];
        }
      }
      return colorScale[colorScale.length - 1];
    }
  }
  return "#000000";
};

const MapWithPolygons = (props) => {
  const [resultData] = useState(props.resultData);
  const [polygonCoordKab, setPolygonCoordKab] = useState([]);
  const [polygonCoordKec, setPolygonCoordKec] = useState([]);
  const [polygonCoordDesa, setPolygonCoordDesa] = useState([]);

  const fetchDataMap = async (url) => {
    try {
      const response = await fetch(url, {
        method: "GET",
      });

      if (!response || response.status === 404) {
        console.error("Data not found or URL is empty");
        return [];
      }

      const responseData = await response.json();

      if (
        responseData &&
        responseData.features &&
        responseData.features.length > 0
      ) {
        const mapPolygon = [];
        const coordinates = responseData.features[0].geometry.coordinates;

        if (coordinates && coordinates.length > 0) {
          if (responseData.features[0].geometry.type === "MultiPolygon") {
            coordinates.forEach((coords) => {
              const polygon = [];
              coords[0].forEach((coord) => {
                polygon.push([coord[1], coord[0]]);
              });
              mapPolygon.push(polygon);
            });
          } else {
            coordinates[0].forEach((item) => {
              mapPolygon.push([item[1], item[0]]);
            });
          }
        } else {
          console.error("Coordinates are empty or missing");
          return [];
        }

        return mapPolygon;
      } else {
        console.error("Response data is empty or missing expected structure");
        return [];
      }
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  };

  useEffect(() => {
    const fetchDataMapKab = (k1, k2) =>
      fetchDataMap(`${CDN_URL}statics/geojson/${k1}/${k2}.json`);

    const fetchDataMapKec = (k1, k2, k3) =>
      fetchDataMap(`${CDN_URL}statics/geojson/${k1}/${k2}/${k3}.json`);

    const fetchDataMapDesa = (k1, k2, k3, k4) =>
      fetchDataMap(`${CDN_URL}statics/geojson/${k1}/${k2}/${k3}/${k4}.json`);

    if (resultData && resultData.data && resultData.data.list_desa) {
      const isKecamatan = getSlugType() === "kecamatan";
      const kecamatanKode = resultData?.dss?.k3; // Mendapatkan kode kecamatan

      // Filter desa berdasarkan kode kecamatan, jika mode kecamatan
      const filteredDesa = isKecamatan
        ? resultData.data.list_desa.filter((item) => item.k3 === kecamatanKode)
        : resultData.data.list_desa; // Jika bukan kecamatan, tampilkan semua desa

      if (filteredDesa.length === 0 && isKecamatan) {
        console.error(
          `Tidak ada desa yang cocok dengan kecamatan ${kecamatanKode}`
        );
      }

      // Proses desa yang sudah difilter
      const newPolygonCoordDesa = filteredDesa.map(async (item) => {
        let mapPolygonResponse = null;
        if (item.k1 && item.k2 && item.k3 && item.k4) {
          // Fetch data polygon jika semua kode desa ada
          mapPolygonResponse = await fetchDataMapDesa(
            item.k1,
            item.k2,
            item.k3,
            item.k4
          );
        }

        return {
          provinsi: resultData.dss.provinsi,
          kabupaten: resultData.dss.kabkota,
          kecamatan: item.nama_kecamatan,
          deskel: item.nama_deskel,
          link: item.slug_desa,
          ar: item.capaian?.ar,
          idm: item.capaian?.idm,
          kd: item.capaian?.kd,
          program: item.capaian?.program,
          sdgs: item.capaian?.sdgs,
          lk: item.potensi?.lk,
          sarpras: item.potensi?.sarpras,
          sda: item.potensi?.sda,
          sdm: item.potensi?.sdm,
          polyDes: mapPolygonResponse,
        };
      });

      Promise.all(newPolygonCoordDesa)
        .then((completedPolygonCoordDesa) => {
          setPolygonCoordDesa(completedPolygonCoordDesa);
        })
        .catch((error) => {
          console.error("Error fetching desa data:", error);
        });
    }

    if (resultData && resultData.data && resultData.data.list_kecamatan) {
      const isKecamatan = getSlugType() === "kecamatan"; // Cek jika mode kecamatan
      const kecamatanData = resultData.data.list_kecamatan;

      if (isKecamatan) {
        const kecamatanKode = resultData?.dss?.k3; // Dapatkan kode kecamatan

        if (kecamatanKode && kecamatanKode !== "00") {
          // Filter kecamatan berdasarkan kode kecamatan yang dipilih
          const filteredKecamatan = kecamatanData.filter(
            (item) => item.k3 === kecamatanKode
          );

          if (filteredKecamatan.length > 0) {
            const promises = filteredKecamatan.map(async (item) => {
              const mapPolygonData = await fetchDataMapKec(
                item.k1,
                item.k2,
                item.k3
              );
              return {
                polyKec: mapPolygonData,
                nama_kecamatan: item.nama_kecamatan,
              };
            });

            // Set data polygon kecamatan yang baru
            Promise.all(promises)
              .then((newPolygonCoordKec) => {
                setPolygonCoordKec(newPolygonCoordKec); // Menyimpan data polygon kecamatan
              })
              .catch((error) => {
                console.error("Error fetching kecamatan data:", error);
              });
          } else {
            console.error(
              `Tidak ada kecamatan yang cocok dengan kode ${kecamatanKode}`
            );
          }
        } else {
          console.error("Kode kecamatan tidak ditemukan atau tidak valid.");
        }
      } else {
        // Mode kabupaten: Tampilkan semua kecamatan
        const promises = kecamatanData.map(async (item) => {
          const mapPolygonData = await fetchDataMapKec(
            item.k1,
            item.k2,
            item.k3
          );
          return {
            polyKec: mapPolygonData, // Polygon data untuk setiap kecamatan
            nama_kecamatan: item.nama_kecamatan,
          };
        });

        // Set data polygon untuk semua kecamatan
        Promise.all(promises)
          .then((newPolygonCoordKec) => {
            setPolygonCoordKec(newPolygonCoordKec); // Menyimpan semua polygon kecamatan
          })
          .catch((error) => {
            console.error("Error fetching all kecamatan data:", error);
          });
      }
    }

    if (
      resultData &&
      resultData.data &&
      resultData.data.list_kabupaten &&
      resultData.data.list_kabupaten.length > 0
    ) {
      const newPolygonCoordKab = resultData.data.list_kabupaten[0];

      if (
        newPolygonCoordKab &&
        newPolygonCoordKab.k1 &&
        newPolygonCoordKab.k2
      ) {
        fetchDataMapKab(newPolygonCoordKab.k1, newPolygonCoordKab.k2)
          .then((polygonData) => {
            setPolygonCoordKab(polygonData);
          })
          .catch((error) => {
            console.error("Error fetching kabupaten data:", error);
          });
        // }
      } else {
        console.error(
          "Data tidak valid untuk pengambilan kabupaten. 'k1' atau 'k2' hilang."
        );
      }
    }
  }, [resultData]);

  const [selectedOption, setSelectedOption] = useState("sdm");

  const kabOptions = {
    color: "white",
    fillColor: "#D4DCC2",
    weight: "2",
    fillOpacity: "1",
  };
  const kecOptions = {
    color: "white",
    fillColor: "#D4DCC2",
    weight: "1",
    fillOpacity: "1",
  };
  const desaOptions = {
    color: "white",
    fillColor: "#C00000",
    weight: "1",
    fillOpacity: "1",
  };

  const handleChangeOption = (option) => {
    setSelectedOption(option);
  };

  const generateLegend = () => {
    const colorScales = {
      kd: ["#03A00A", "#C00000", "#327A6D"],
      idm: ["#03A00A", "#D3D714", "#C00000", "#0DC3A2", "#327A6D"],
      sdgs: ["#03A00A", "#D3D714", "#C00000", "#0D77C3", "#0DC3A2", "#327A6D"],
      ar: ["#03A00A", "#D3D714", "#C00000", "#0D77C3", "#0DC3A2", "#327A6D"],
      program: [
        "#03A00A",
        "#D3D714",
        "#C00000",
        "#0D77C3",
        "#0DC3A2",
        "#327A6D",
      ],
      sda: ["#03A00A", "#D3D714", "#C00000", "#0D77C3", "#0DC3A2", "#327A6D"],
      sdm: ["#03A00A", "#D3D714", "#C00000", "#0D77C3", "#0DC3A2", "#327A6D"],
      lk: ["#03A00A", "#D3D714", "#C00000", "#0D77C3", "#0DC3A2", "#327A6D"],
      sarpras: [
        "#03A00A",
        "#D3D714",
        "#C00000",
        "#0D77C3",
        "#0DC3A2",
        "#327A6D",
      ],
    };
    const thresholds = {
      kd: ["SWASEMBADA", "SWAKARYA", "SWADAYA"],
      idm: ["SANGAT TERTINGGAL", "TERTINGGAL", "BERKEMBANG", "MAJU", "MANDIRI"],
      sdgs: ["< 10", "11 - 20", "21 - 30", "31 - 50", "51 - 80", "> 80"],
      ar: ["0", "1 - 3", "4 - 5", "6 - 8", "9 - 10", "> 10"],
      program: ["0", "1 - 3", "4 - 5", "6 - 8", "9 - 10", "> 10"],
      sda: ["0", "1 - 3", "4 - 5", "6 - 8", "9 - 10", "> 10"],
      sdm: [
        "< 100",
        "101 - 500",
        "501 - 1.000",
        "1.001 - 3,000",
        "3.001 - 5.000",
        "> 5.000",
      ],
      lk: ["0", "1 - 3", "4 - 5", "6 - 8", "9 - 10", "> 10"],
      sarpras: ["0", "1 - 3", "4 - 5", "6 - 8", "9 - 10", "> 10"],
    };

    const colorScale = colorScales[selectedOption];
    const legendValues = thresholds[selectedOption];

    return (
      <div className="row g-0">
        <div className="col-sm-2 fw-bold my-2">Keterangan:</div>
        <div className="col-sm-10 mt-2">
          <div className="row">
            {legendValues.map((legend, index) => (
              <div className="col-sm-4" key={index}>
                <div className="row">
                  <p className="col-12">
                    <i
                      className="bi bi-square-fill"
                      style={{ color: colorScale[index] }}
                    ></i>{" "}
                    {legend}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <div className="row g-1 mb-4">
        <div className="col-4">
          <select
            value={selectedOption}
            onChange={(e) => handleChangeOption(e.target.value)}
            className="form-select"
            aria-label="Default select example"
          >
            <option value="kd">[Capaian] Klasifikasi Desa</option>
            <option value="idm">[Capaian] Indeks Desa Membangun</option>
            <option value="sdgs">[Capaian] SDGs</option>
            <option value="ar">[Capaian] AR</option>
            <option value="program">[Capaian] Program</option>
            <option value="sda">[Potensi] Sumber Daya Alam</option>
            <option value="sdm">[Potensi] Sumber Daya Manusia</option>
            <option value="lk">[Potensi] Lembaga Kemasyarakatan</option>
            <option value="sarpras">[Potensi] Sarana & Prasarana</option>
          </select>
        </div>
        <div className="col-3 d-none">
          <button type="button" className="btn btn-cari">
            <i className="bi bi-search me-1"></i> Temukan
          </button>
        </div>
      </div>
      {polygonCoordKab && polygonCoordKab.length > 0 && (
        <MapContainer
          zoom={10}
          scrollWheelZoom={false}
          style={{ height: "500px" }}
          bounds={
            polygonCoordKec.length > 0
              ? polygonCoordKec.map(({ polyKec }) => polyKec) // Jika ada kecamatan, gunakan  kecamatan
              : polygonCoordKab || [
                  // Jika tidak, fallback ke kabupaten
                  [-90, -180],
                  [90, 180],
                ]
          }
        >
          <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png" />

          {/* Render Poligon Kabupaten jika tidak ada kecamatan yang aktif */}
          {polygonCoordKec.length === 0 && (
            <Polygon positions={polygonCoordKab} pathOptions={kabOptions} />
          )}

          {/* Render Poligon Kecamatan jika ada kecamatan yang aktif */}
          {polygonCoordKec.length > 0 &&
            polygonCoordKec.map(({ polyKec }, index) => {
              return (
                <Polygon
                  key={index}
                  positions={polyKec}
                  pathOptions={kecOptions}
                />
              );
            })}

          {/* Render Poligon Desa */}
          {polygonCoordDesa.map(
            (
              {
                polyDes,
                provinsi,
                kabupaten,
                kecamatan,
                deskel,
                link,
                kd,
                idm,
                sdgs,
                ar,
                program,
                sda,
                sdm,
                lk,
                sarpras,
              },
              index
            ) => {
              const capaianValue =
                selectedOption === "kd"
                  ? kd
                  : selectedOption === "idm"
                  ? idm
                  : selectedOption === "sdgs"
                  ? sdgs
                  : selectedOption === "ar"
                  ? ar
                  : selectedOption === "program"
                  ? program
                  : selectedOption === "sda"
                  ? sda
                  : selectedOption === "sdm"
                  ? sdm
                  : selectedOption === "lk"
                  ? lk
                  : selectedOption === "sarpras"
                  ? sarpras
                  : 0;

              const desaColor = getColorForValue(capaianValue, selectedOption);
              const desaPathOptions = { ...desaOptions, fillColor: desaColor };
              return (
                <Polygon
                  key={index}
                  positions={polyDes}
                  pathOptions={desaPathOptions}
                >
                  <Popup closeButton={false}>
                    <div className="card-map">
                      <div className="card-body-map">
                        <h5 className="title-desa card-title-potensi p-0">
                          Desa {deskel}
                        </h5>
                        <p className="text-capitalize">
                          Kec. {kecamatan}, {kabupaten.toString().toLowerCase()}
                          , Prov. {provinsi.toString().toLowerCase()}
                        </p>
                        <div className="filter-primary">
                          <a
                            href={`https://${link}.digitaldesa.id`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <h5>
                              <span className="badge bg-cctv">
                                <i className="bx bx-cctv"></i> CCTV
                              </span>
                            </h5>
                          </a>
                        </div>
                        <div className="row">
                          <div className="col-md fw-bold">
                            <h5 className="fw-bold">Capaian</h5>
                            <div className="row g-2">
                              <div className="col-6">KD</div>
                              <div className="col-6">: {kd}</div>
                              <div className="col-6">IDM</div>
                              <div className="col-6">: {idm}</div>
                              <div className="col-6">SDGs</div>
                              <div className="col-6">: {sdgs}</div>
                              <div className="col-6">AR</div>
                              <div className="col-6">: {ar}</div>
                              <div className="col-6">Program</div>
                              <div className="col-6">: {program}</div>
                            </div>
                          </div>
                          <div className="col-md fw-bold">
                            <h5 className="fw-bold">Potensi</h5>
                            <div className="row g-2">
                              <div className="col-6">SDA</div>
                              <div className="col-6">: {sda}</div>
                              <div className="col-6">SDM</div>
                              <div className="col-6">: {sdm}</div>
                              <div className="col-6">LK</div>
                              <div className="col-6">: {lk}</div>
                              <div className="col-6">SarPras</div>
                              <div className="col-6">: {sarpras}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Popup>
                </Polygon>
              );
            }
          )}
        </MapContainer>
      )}

      {generateLegend()}
    </Fragment>
  );
};

export default MapWithPolygons;
